// http://ionicframework.com/docs/theming/
@import '../node_modules/@ionic/angular/css/core.css';
@import '../node_modules/@ionic/angular/css/normalize.css';
@import '../node_modules/@ionic/angular/css/structure.css';
@import '../node_modules/@ionic/angular/css/typography.css';
@import '../node_modules/@ionic/angular/css/display.css';
@import '../node_modules/@ionic/angular/css/padding.css';
@import '../node_modules/@ionic/angular/css/float-elements.css';
@import '../node_modules/@ionic/angular/css/text-alignment.css';
@import '../node_modules/@ionic/angular/css/text-transformation.css';
@import '../node_modules/@ionic/angular/css/flex-utils.css';
@import '../node_modules/lightgallery/css/lightgallery.css';
@import '../node_modules/lightgallery/css/lg-zoom.css';



html {
    font-size: 14px;
}

html {
    -webkit-text-size-adjust: 100%; /* Para navegadores Webkit (Safari, Chrome en iOS) */
    text-size-adjust: 100%;         /* Para otros navegadores */
}

ion-avatar {
    margin: 0;
    display: flex;
    align-items: center;
}

.searchbar-input {
    border-radius: 64px !important;
}

ion-segment-button, ion-item-option {
    text-transform: none;
}
ion-toolbar{
    height: 45px;
}

button.alert-tappable.sc-ion-alert-md {
    position: relative;
    overflow: hidden;
    --height: 33px;
}

ion-select-option{
    .select-interface-option {
        height: 32px !important;
    }
}
/*
.sc-ion-alert-md-h {
    --height: 80vh;
}

.alert-wrapper.sc-ion-alert-md {
    --height: 80vh;
}

.alert-radio-group .alert-radio-label {
    padding: 0 -16px;
}

.alert-radio-group {
    --height: 80vh;
}
*/
.md .alert-radio-button {
    max-height: 33px;
    --height: 33px;
}

.tblHorarioSemanal{
    width: 100%;
    font-size: 1rem;
}

.tblHorarioSemanal td{
    padding:2px !important;
    padding-top:0px;
}

.page-list ion-col{
    //height: 54px;
    padding: 3px 3px 0px 3px;
}
.page-list ion-item{
   // height: 54px;
   min-height: 54px;
    --padding-start: 0;
    --padding-end: 0;
    --padding-top: 0;
    --padding-bottom: 0;
    --inner-padding-top: 0;
    --inner-padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 0;
}

.servicio-horario-semanal{
    --inner-padding-start:0;
    --padding-start: 0;
    --padding-top: 0;
    --inner-border-width: 0;
}

.datetime-button-disabled::part(native){
    background: #FFF;
    color:black;
}
.label-datetime{
    margin-bottom: 5px !important;
    transform: translateY(50%) scale(0.75) !important;
}

.sheet-margin-top {
    margin-top: 8px;
    margin-bottom: 0;
}


.sheet-padding-start{
    padding-left: 16px;
    padding-top: 0px
}

.sheet-hour{
    font-size: 1rem
}

.service-company-logo {
    max-width: none;
    width: 54px;
    height: 54px;
}

.flex{
    display: flex;
}

.flex-column{
    flex-direction: column;
}

.justify-center{
    justify-content: center;
}

.justify-end{
    justify-content: flex-end;
}

.gap-1 {
    gap: 0.25rem;
}

.w-max-full{
    max-width: 100%;
}

.w-full{
    width: 100%;
}

.planing-color {
    color: var(--ion-color-planing)
}

.pending-color {
    color: var(--ion-color-pending)
}

.in-progress-color {
    color: var(--ion-color-in-progress)
}

.finished-color {
    color: var(--ion-color-finished)
}

.warning-color {
    color: var(--ion-color-warning)
}

.danger-color {
    color: var(--ion-color-danger)
}

.invoiced-color {
    color: var(--ion-color-invoiced)
}

.group-list-title {
    font-size: 1rem !important;
    font-weight: bold;
    --color: #5e5e5e !important;
}

.group-list-total {
    font-size: 1rem !important;
}	

.font-bold {
    font-weight: bold;
}

.font-semibold {
    font-weight: 600;
}

.padding-left-16 {
    padding-left: 16px !important;
}	

.pt-xxs {
    padding-top: 5px !important;
}

.pt-0 {
    padding-top: 0 !important;
}

.pl-0 {
    padding-left: 0 !important;
}

.pl-4 {
    padding-left: 1rem;
}

.pr-4 {
    padding-right: 1rem;
}

.pl-2 {
    padding-left: 0.5rem;
}

.pr-2 {
    padding-right: 0.5rem;
}

.p-4 {
    padding: 1rem;
}
.pt-6 {
    padding-top: 1.5rem;
}
.pb-0 {
    padding-bottom: 0;
}	

.margin-0 {
    margin: 0;
}

.mt-4 {
    margin-top: 1rem;
}

.padding-0 {
    padding: 0;
}
.header-accordeon {
    display: flex;
    justify-content: space-between;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 8px;
    padding-bottom: 0;
    color: #5e5e5e;
}

ion-header {
    ion-toolbar {
        --background: white;
    }
}

.input-icon {
    padding-top: 10px;
    padding-right: 3px
}

.kflow-text-one-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.kflow-text-font-basic {
    font-size: var(--kflow-font-size-basic) !important;
}

.kflow-text-font-small {
    font-size: 12px !important;
}

.kflow-text-font-x-small {
    font-weight: 500;
    font-size: 11px !important;
}

.justify-content-between {
    justify-content: space-between;
}

.kflow-header-title {
    font-size: 1rem; 
    font-weight: 700;
    margin: 0;
    color: black;
}

.kflow-state{
    text-align: left;
    padding-left: 0px; 
    font-size: 1rem; 
    font-weight: 500; 
    background-color: white;
}

.privacy-policy{
    position: absolute;
    bottom: 10px;
    width: 100%;
    padding: 15px;
    font-size: 12px;
    text-align: center;
}

.signing{
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: left;
    margin-top: 3px;
    ion-toggle{
        font-weight: bold;
        font-size: 1rem;
    }
    ion-text {
        padding-right: 16px;
        font-size: 1rem;
        p {
            margin-top:3px;
            margin-bottom: 3px;

        }
    }
}
.beacon-list{
    padding-top: 0;
}

.beacon-item{
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
}

// #region css Inputs
:root {
    --ion-background-color-step-300: var(--kflow-background-color-input);
    --background: var(--kflow-background-color-input);
}

.gray-input {
    --background: var(--kflow-background-color-input);
}
ion-select {
    --background: var(--kflow-background-color-input) !important;
    --border-radius: 8px;
}

ion-textarea {
    --border-radius: 8px !important; 
}

ion-input {
    --border-radius: 8px !important;
}
// #endregion

.padding-bottom-6 {
    padding-bottom: 6px !important;
}

.kflow-background-edit{
    background-color: var(--kflow-background-color-input);
    border-radius: 8px;
    padding: 6px;
}

.w-85 {
    width: 85%;
}

.text-center {
    text-align: center;
}
.edit-service-sv {
    color: var(--ion-color-invoiced);
}


#actions-expected-presences  {
    .action-sheet-icon.sc-ion-action-sheet-md {
        margin-inline-end: 0.3rem !important;
    }
    
    .action-sheet-button{
        padding-top: 0;
        padding-bottom: 0.5rem;
        min-height: 2.5rem !important;
    }

    .action-sheet-group {
        padding-bottom: 1rem;
    }

    .action-sheet-title {
        padding-bottom: 0px;
        min-height: 45px;
        font-size: 16px; 
        font-weight: 700;
        color: black;
    }
}

.alert-subactivity{
    .alert-title{
        font-size: 16px; 
        font-weight: 700;
        color: black;
    }
}

#date-button {
    padding-inline-start: 0 !important;
    padding-inline-end: 0;
}

ion-datetime-button{
    font-size: var(--kflow-font-size-basic);
    button {
        font-size: var(--kflow-font-size-basic);
        padding-inline-start: 0 !important;
        padding-inline-end: 0;
        justify-content: flex-start;
    }
    justify-content: flex-start;
}

.flex-wrap {
    flex-wrap: wrap;
}

.btn-big {
    font-size: 18px;
    --padding-top: 0;
    --padding-start: 0;
    --padding-end: 0;
    --padding-bottom: 0;
  }

.kflow-btn-actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    ion-button {
        min-height: 0;
        margin-bottom: 0;
    }
}



.align-items-center {
    align-items: center;
}

.align-items-start {
    align-items: flex-start;
}

.text-title { // text title blue
    color: #4c96d7;
}

.max-width-100 {
    max-width: 100% !important;
}

.kflow-filter-input {
    --background: var(--kflow-background-color-input);
    color: var(--ion-color-primary);
    }

// comment
